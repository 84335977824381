<template>
    <a-modal title="Expense operation's information" visible :onCancel="handleCancel" okText="Done" @ok="onSubmit"
        :ok-button-props="{
            disabled: !isConditionalType
        }">
        <div>
            <FormValidationWrap>
                <VerticalFormStyleWrap>
                    <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState"
                        :rules="rules" :layout="formState.layout">
                        <a-row :gutter="25">

                            <a-col :span="12">
                                <a-form-item label="Expense" name="bankOperationTypeId">
                                    <a-select v-model:value="formState.bankOperationTypeId" placeholder="Expense"
                                        @change="formState.partenaireId = null">
                                        <a-select-opt-group label="Outcome">
                                            <a-select-option v-for="(
                                expenseType, index
                              ) in ExpenseTypeApi.filter(
                                    (operation) => operation.incomeOrOutcome == 2 && operation.isExpense
                                )" :key="index" :value="expenseType.id">
                                                {{ expenseType.name }}</a-select-option>
                                        </a-select-opt-group>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12" v-if="isConditionalType"><a-form-item label="Partner"
                                    name="partenaireId">
                                    <a-select v-if="partnerApi.length" v-model:value="formState.partenaireId"
                                        placeholder="Please choose Owner" show-search style="width: 100%"
                                        :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                                            " :filter-option="false"
                                        @search="(value) => searchPartner(value, formState.operationSupport == 1, formState.operationSupport == 2)"
                                        @change="fetchPartnerPurchase('')">
                                        <template v-if="partnerFetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                    <a-select v-else v-model:value="formState.partenaireId"
                                        placeholder="Please choose Owner">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12" v-if="isConditionalType"><a-form-item :label="itemsIdLable"
                                    name="itemIds">
                                    <a-select v-model:value="formState.itemIds" placeholder="Select PO" mode="multiple"
                                        style="width: 100%" :filter-option="false" show-search
                                        :disabled="!formState.partenaireId"
                                        :not-found-content="purchaseState.fetching ? undefined : null"
                                        :options="purchaseState.data" @search="fetchPartnerPurchase"
                                        @change="setAmount">
                                        <template v-if="purchaseState.fetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12">
                                <a-form-item ref="amount" label="Amount" name="amount">
                                    <a-input-number :style="{ width: '100%' }" size="small"
                                        v-model:value="formState.amount" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="12">
                                <a-form-item ref="vat" label="Vat" name="vat">
                                    <a-input-number :style="{ width: '100%' }" :min="0" :max="100"
                                        :formatter="(value) => `${value}%`" :parser="(value) => value.replace('%', '')"
                                        v-model:value="formState.vat" placeholder="VAT" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12">
                                <a-form-item name="date" label="Date">
                                    <a-date-picker v-model:value="formState.date" :style="{ width: '100%' }"
                                        :format="dateFormat" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12">
                                <a-form-item name="supplierReceiptReference" label="Supplier receipt reference">
                                    <a-input v-model:value="formState.supplierReceiptReference" size="large"
                                        :style="{ width: '100%' }" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12">
                                <a-form-item label="Receipt type" name="receiptType">
                                    <a-select v-model:value="formState.receiptType" placeholder="Receipt type"
                                        style="width: 100%">
                                        <a-select-option :value="1">Final invoice</a-select-option>
                                        <a-select-option :value="2">Performa invoice</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12">
                                <a-form-item ref="ExpensePurchase" label="Expense Files" name="ExpensePurchase">
                                    <a-upload v-model:file-list="expenseFiles" name="ExpensePurchase" :multiple="true"
                                        :customRequest="uploadfiles" :style="{ width: '100%' }" :showUploadList="false">
                                        <a-button class="btn-outlined" size="large" :outlined="true">
                                            <upload-outlined></upload-outlined>
                                            <span>Select Files</span>
                                        </a-button>
                                    </a-upload>
                                    <div v-if="expenseFiles.length">
                                        <div v-for="file in expenseFiles" :key="file.uid" class="file-item">
                                            <span class="file-text" :title="file.fileName">{{ file.fileName }}</span>
                                            <a @click="handleDownload(file)">
                                                <sdFeatherIcons type="download" />
                                            </a>
                                            <a @click="removeFile(file)">
                                                <sdFeatherIcons type="trash-2" />
                                            </a>
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12"
                                v-if="$userHasPermission('Permissions.Expense.CanValidate') && props.expenseOperation?.id && !props.expenseOperation?.isValid">
                                <a-button type="primary" @click="validateExpense">
                                    Validate operation
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                </VerticalFormStyleWrap>
            </FormValidationWrap>
        </div>

    </a-modal>
</template>
<script setup>
import { useStore } from "vuex";
import { reactive, ref, computed, defineProps, onMounted, defineEmits } from "vue";
import moment from "moment";
import { useFileDownload } from '@/composable/useFileDownload';
import usePartnerSearch from "@/composable/usePartnerSearch";
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../../forms/overview/Style";
import { DataService } from "@/config/dataService/dataService";
import { debounce } from 'lodash-es';

const { state, dispatch } = useStore();
const { downloadFile } = useFileDownload();

/// Apiss data
const ExpenseTypeApi = computed(
    () => state.ExpenseTypeApi.data
);
const expenseFiles = computed(() => state.FileApi.data);
const partnerApi = computed(() => state.partnerApi.data);
const purchaseState = reactive({
    data: [],
    value: [],
    fetching: false,
});
////
const props = defineProps(['expenseOperation', 'pagination']);
const emit = defineEmits(['cancel', 'validate'])
//form
const dateFormat = "YYYY/MM/DD";

const rules = {
    bankOperationTypeId: [
        {
            required: true,
            message: "Expense type is required",
            trigger: "change",
        },
    ],
    date: [
        {
            type: "object",
            required: true,
            message: "Date is required",
            trigger: "change",
        },
    ],
    amount: [
        {
            type: "number",
            required: true,
            message: "Amount is required",
            trigger: "change",
        },
    ],
    bankAccountId: [
        {
            required: true,
            message: "Expense account is required",
            trigger: "change",
        },
    ],
    partenaireId: [
        {
            required: true,
            message: "Partner is required",
            trigger: "change",
        },
    ],
    occasionalName: [
        {
            required: true,
            message: "Name is required",
            trigger: "change",
        },
    ],
    isPartnerOrOccasional: [
        {
            type: "number",
            required: true,
            message: "Benificiary is required",
            trigger: "change",
        },
    ],
    paymentMethodId: [
        {
            required: true,
            message: "Payment Method is required",
            trigger: "change",
        },
    ],
    itemIds: [
        {
            type: "array",
            required: true,
            message: "Purchase number is required",
            trigger: "change",
        },
    ],
    supplierReceiptReference: [
        {
            required: true,
            message: "Supplier receipt reference is required",
            trigger: "change",
        },
    ],
    receiptType: [
        {
            type: "number",
            required: true,
            message: "Receipt type is required",
            trigger: "change",
        },
    ],
};
const formRef = ref();
var formState = reactive({
    layout: "vertical",
    id: 0,
    date: "",
    amount: null,
    vat: 0,
    partenaireId: null,
    supplierReceiptReference: null,
    receiptType: null,
    bankOperationTypeId: null,
    itemIds: [],
    note: "",
});

const isPurchasingOfGoods = computed(() => {
    const expenseType = ExpenseTypeApi.value.find(obj => obj.id === formState.bankOperationTypeId)
    return expenseType?.code == "purchasing_goods"
})
const isTransportRequest = computed(() => {
    const expenseType = ExpenseTypeApi.value.find(obj => obj.id === formState.bankOperationTypeId)
    return expenseType?.code == "transport_request_partner"
})
const isInspectionRequest = computed(() => {
    const expenseType = ExpenseTypeApi.value.find(obj => obj.id === formState.bankOperationTypeId)
    return expenseType?.code == "inspection_request_partner"
})
const isConditionalType = computed(() => {
    return isPurchasingOfGoods.value ||
        isTransportRequest.value ||
        isInspectionRequest.value;
})

const itemsIdLable = computed(() => {
    var label = "Number";
    if (isPurchasingOfGoods.value) {
        label = "Purchases";
    }
    if (isTransportRequest.value) {
        label = "Transport Requests";
    }
    if (isInspectionRequest.value) {
        label = "Inspection Requests";
    }
    return label
})


const handleEdit = async (expenseOperation) => {
    fetchExpenseDetails(expenseOperation.id);
    formState.partenaireId = expenseOperation.partenaireId;
    formState.editionMode = true;
    formState.id = expenseOperation.id;
    formState.date = moment(expenseOperation.date);
    formState.amount = expenseOperation.amount;
    formState.supplierReceiptReference = expenseOperation.supplierReceiptReference;
    formState.receiptType = expenseOperation.receiptType;
    formState.bankOperationTypeId = expenseOperation.bankOperationTypeId;
    if (expenseOperation.files) {
        dispatch("setIntialFiles", expenseOperation.files);
    }
    if (expenseOperation.partenaireId) {
        partnerData.value.push({
            value: expenseOperation.partenaireId,
            label: expenseOperation.partenaireName,
        });
    }
    formState.partenaireId = expenseOperation.partenaireId;
    formState.occasionalLocalExternal = expenseOperation.occasionalLocalExternal;
    formState.vat = expenseOperation.vat;
    formState.Note = expenseOperation.Note;
    await fetchPartnerPurchase('');
};

const itemsList = ref([]);
const fetchExpenseDetails = async (expenseId) => {
    const response = await DataService.get(`api/Expense/${expenseId}/ItemsDetails`);
    itemsList.value = response.data;
}

const onSubmit = () => {
    formRef.value
        .validate()
        .then(() => {
            formState.name = "hideedn"
            if (!formState.editionMode) {
                formState.filesNotSaved = expenseFiles.value;
                dispatch("expenseOperationSubmitData", {
                    formState,
                    pagination: props.pagination
                });
            } else {
                dispatch("expenseOperationUpdateData", {
                    formState,
                    pagination: props.pagination
                });
            }
            handleCancel();
        })
        .catch((error) => {
            console.log("error", error);
        });
};

const { partnerData, searchPartner } = usePartnerSearch(
    formState.partenaireId
);

onMounted(() => {
    emptyPartner();
    if (props.expenseOperation) {
        handleEdit(props.expenseOperation);
    } else {
        formState.editionMode = false;
        delete formState.id;
        formState.name = "";
        formState.date = "";
        formState.estimatedTime = "";
        formState.isPartnerOrOccasional = null;
        formState.supplierReceiptReference = null;
        formState.receiptType = null;
        formState.amount = "";
        formState.bankOperationTypeId = null;
        formState.paymentMethodId = null;
        formState.operationSupport = 1;
        formState.occasionalName = "";
        formState.partenaireId = "";
        formState.bankAccountId = "";
        formState.itemIds = [];
        formState.vat = 0;
        formState.Note = "";
        formState.occasionalLocalExternal = "";
    }
});

const uploadfiles = (file) => {
    var formData = new FormData();
    formData.append("File", file.file);
    if (props.expenseOperation?.id) {
        formData.append("ItemId", props.expenseOperation.id);
        formData.append("ItemParentId", props.expenseOperation.id);
    }
    formData.append("ItemParentType", "ExpensePurchase");
    formData.append("ItemType", "ExpensePurchase");

    formData.append("FileType", file.filename);
    formData.append("FileName", file.file.name);
    dispatch("fileSubmitData", {
        formData,
    });
};
const handleDownload = async (file) => {
    // Pass the file ID to initiate download
    await downloadFile(file);
};
const removeFile = (file) => {
    dispatch("removeFile", file);
};

const handleCancel = () => {
    if (expenseFiles.value.length && formState.id) {
        let files = expenseFiles.value;
        let id = formState.id;
        dispatch("updateExpenseFiles", { files, id });
    }
    emit('cancel');
}

const validateExpense = () => {
    if (expenseFiles.value.length && formState.id) {
        let files = expenseFiles.value;
        let id = formState.id;
        dispatch("updateExpenseFiles", { files, id });
    }

    emit('validate', props.expenseOperation);
    emit('cancel');
}

const emptyPartner = () => {
    partnerData.value = [];
    formState.partenaireId = null;
};
const setAmount = () => {

    if (formState.itemIds.length == 0) {
        formState.amount = 0;
    } else {
        formState.amount = purchaseState.data
            .filter(purchase => formState.itemIds.includes(purchase.id))
            .reduce((sum, purchase) => sum + purchase.amount, 0);
    }

};

let lastPartneritemIds = 0;
const fetchPartnerPurchase = debounce(async (value) => {
    if (value === '') {
        formState.itemIds = [];
    }

    lastPartneritemIds += 1;
    const fetchId = lastPartneritemIds;
    purchaseState.data = [];
    purchaseState.fetching = true;

    try {
        var url = "";
        if (isPurchasingOfGoods.value) {
            url = `api/Purchase/GetAllPurchaseShort?Number=${value}&PartnerId=${formState.partenaireId}&OnlyNotUsed=false&PurchaseStatus=2`;
        }
        if (isTransportRequest.value) {
            url = `api/TransportRequest/TransportRequestPartner?Fields=id%2Cnumber%2Cprice&PartnerId=${formState.partenaireId}&addFiles=false&isValid=true`;
        }
        if (isInspectionRequest.value) {
            url = `api/InspectionRequest/InspectionRequestPartner?Fields=id%2Cnumber%2Cprice&PartnerId=${formState.partenaireId}&addFiles=false&isValid=true`;
        }
        const response = await DataService.get(url);

        if (fetchId !== lastPartneritemIds) {
            return;
        }

        const list = response.data;
        const data = list.map((element) => ({
            label: element.number,
            id: element.id,
            value: element.id,
            amount: element.totalTTC ?? element.price,
        }));

        purchaseState.data = data;
        if (props.expenseOperation) {
            updateMissingDetails(props.expenseOperation.itemIds).then(() => {
                formState.itemIds = props.expenseOperation.itemIds;
            })
        }
    } catch (error) {
        console.error("Error fetching purchase data:", error);
    } finally {
        purchaseState.fetching = false;
    }
}, 300);

const updateMissingDetails = async (ids) => {
    // Assuming purchaseState.data and itemsList.value are available in your scope
    const itemsSet = new Set(purchaseState.data.map(obj => obj.id));
    const missingIds = ids.filter(id => !itemsSet.has(id));

    for (const missingId of missingIds) {
        // Simulate fetching or any other async operation
        const missingObject = itemsList.value.find(obj => obj.id === missingId);

        if (missingObject) {
            missingObject.value = missingObject.id;
            missingObject.label = missingObject.number;
            missingObject.amount = missingObject.price;
            purchaseState.data.push(missingObject);
        }
    }
};
</script>

<style scoped>
.file-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-item {
    margin-top: 4px;
    display: flex;
}

.file-item a,
.file-item span {
    margin-left: 8px;
    cursor: pointer;

    &:nth-child(2) {
        color: #1890ff !important;
    }

    &:last-child {
        color: red !important;
    }
}
</style>